import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { useQuery } from "react-query"

// Layout
import KnockOut from "../components/layout/KnockOut"
import {
  AgreementFields,
  Form,
  Fieldset,
  FormField,
  FormTextArea,
  Button,
} from "../components/forms"

// Utility
import { Pods } from "../config/db"
import { AuthContext } from "../context/AuthContext"
import { podApplicationSchema } from "../helpers/validation"
import withWindow from "../helpers/withWindow"
import useQueryParams from "../hooks/useQueryParams"
import { sendEmail } from "../helpers/emailHelper"

const initialValues = {
  application: "",
  name: "",
  facebook: "",
  linkedIn: "",
  instagram: "",
  phoneNumber: "",
  emailAddress: "",
  toc: false,
  safetyAndScam: false,
}

const PodApplication = () => {
  const { hasPod } = useContext(AuthContext)

  const params = useQueryParams()
  const id = params.get("id")

  const { data: pod, isLoading } = useQuery(
    "Application - fetchPod",
    async () => Pods.fetchById(id)
  )

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = ({ toc, safetyAndScam, ...application }) => {
    sendEmail("application - applicant", pod, application.emailAddress)
    sendEmail("application - owner", application, pod.emailAddress)

    let applicantEmails
    if (pod.applicantEmails)
      applicantEmails = [...pod.applicantEmails, application.emailAddress]
    else applicantEmails = [application.emailAddress]
    Pods.update({ ...pod, applicantEmails }, pod.id)

    navigate(`/inform?mode=apply&id=${pod?.id}`)
  }

  if (hasPod?.id === id) {
    navigate(`/profile?id=${pod.id}`)
  }

  if (!isLoading && !pod) {
    navigate("/404")
    return null
  }
  let applicantEmails
  if (pod) applicantEmails = [pod.emailAddress]
  if (pod?.applicantEmails) applicantEmails.push(...pod.applicantEmails)

  return (
    <>
      <Helmet>
        <title>Apply to {pod ? pod.podName : "pod"}</title>
      </Helmet>
      {isLoading ? (
        <KnockOut />
      ) : (
        <div className="[ wrapper ] [ pod-profile ] ">
          <Link to={`/profile?id=${pod.id}`}>← Pod profile</Link>
          <h2>Apply to join &quot;{pod.podName}&quot;</h2>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={podApplicationSchema(applicantEmails)}
          >
            <div className="[ flow ]">
              <header className=" [ shadow-box ] [ pod-header ]">
                <Fieldset heading="Pod Application">
                  <ul className="culture-input">
                    <FormTextArea
                      label="Tell the pod owner a little about your family..."
                      name="application"
                    />
                  </ul>
                </Fieldset>
              </header>
              <section className="shadow-box">
                <Fieldset heading="Pod Application">
                  <ul className="[ auto-grid ]">
                    <FormField label="Name" name="name" />
                    <FormField
                      label="Phone number"
                      name="phoneNumber"
                      required
                    />
                    <FormField label="Email" name="emailAddress" required />
                    <FormField label="Facebook" name="facebook" />
                    <FormField label="LinkedIn" name="linkedIn" />
                    <FormField label="Instagram" name="instagram" />
                  </ul>
                </Fieldset>
              </section>
              <AgreementFields />
              <p className="text-center">
                Avoid scams. Beware wiring (e.g. Western Union), cashier checks,
                money orders, shipping.
              </p>
              <Button className="[ green ]" title="Send application" />
            </div>
          </Form>
        </div>
      )}
    </>
  )
}

export default withWindow(PodApplication)
